import React from "react";
import { Link, Element } from 'react-scroll'
import { Helmet } from "react-helmet"
// import ClientCard from '../components/client-card';
import ContactForm from '../components/contact-form';
import Skills from '../components/skills';
import Integrations from '../components/integrations';
import About from '../components/about';


import "./../css/style.scss";


// import Clients from "../../content/clients.yaml"

import DownArrow from "../../static/arrow_down.svg"
import LinkedIn from "../../static/linkedin.svg"
import GitHub from "../../static/github.svg"

export default function Home() {

    return <div className="home">
               <Helmet>
                  <meta charSet="utf-8" />
                  <title data-react-helmet="true">James Podles</title>
                  <link rel="canonical" href="https://jamespodles.com" data-react-helmet="true"/>
              </Helmet>
            <section className="hero">
            <div className="header-links">
                <div class="link"><a href="https://ca.linkedin.com/in/jamespodles" target="_blank"><img src={LinkedIn} alt="LinkedIn" title="LinkedIn" width="32" height="32" /></a></div>
                <div class="link"><a href="https://github.com/jampod-dev" target="_blank"><img src={GitHub} alt="Github" title="Github" width="32" height="32" /></a></div>                
            </div>
                <div className="content">
                    <h1>James Podles</h1>
                    <span className="brackets"> {'{'}{'}'}</span>
                    <h2>full-stack development</h2>
                </div>
                <div className="arrow">
                    <Link to="about" smooth={true} offset={-50}>
                       <img src={DownArrow} alt="Down Arrow" />
                    </Link>
                </div>
            </section>

            
            <About />

            <Skills />

            <Integrations />


            <ContactForm />
        </div>
}


            // <section className="clients">
            //     <Element name="clients">
            //         <h2>Selected Projects</h2>
            //         <ul className="clients unstyled">
            //             {Clients.clients.map((data, index)=> {
            //                 return <ClientCard key={index} data={data} />
            //             })}
            //         </ul>
            //     </Element>
            // </section>