import React, { useState } from "react"
import { Element } from 'react-scroll'
import { useForm, ValidationError } from '@formspree/react';

import axios from "axios"

export default function ContactForm() {
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    })

    const [state, handleSubmit] = useForm("meqvooae");

    if (state.succeeded) {
        return <p className="message">Thank you! I'll be in touch shortly</p>;
    }

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg },
        })
        console.log(ok)
        if (ok) {
            form.reset()
        }
    }
    const handleOnSubmit = e => {
        e.preventDefault()
        const form = e.target
        setServerState({ submitting: true })
        const options = {
            method: "post",
            url: "https://getform.io/f/73445977-c9bb-4b5a-ae48-27588f572977",
            data: new FormData(form),
            headers: {
                "Accept": "application/json"
            }
        }
        console.log(options)

        axios.request(options).then(r => {
            handleServerResponse(true, "Thank you! I'll be in touch shortly.", form)
        }).catch(r => {
            handleServerResponse(false, r.response.data.error, form)
        })
    }

    return (
      <section className="contact">
      <h2>Contact</h2>
      <Element name='contact'>
      <form onSubmit={handleSubmit}>
          <div className="form-group">
              <input id="name" 
                type="text"
                name="name"
                placeholder="Your Name"
                />
            </div>
            <div className="form-group">
              <input
                id="email"
                type="email" 
                name="email"
                placeholder="Email Address"
              />
              <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
              />
            </div>

        <div className="form-group">
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            rows="4"
          />
          <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
          />
          </div>
          <button type="submit" disabled={state.submitting}>
            Submit
          </button>
        </form>



  
      </Element>
    </section>
    )
}