import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from 'react-scroll'

export default function About() {

    const data = useStaticQuery(
        graphql `
      query {
        file(relativePath: { eq: "james-headshot.jpg" }) {
          childImageSharp {
            fixed(width: 240, height: 240) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
    )

    return (
        <section className="about">   
            <h2>About Me</h2>     
            <div className="content">
                <div className="image">
                    <Img className="headshot" fixed={data.file.childImageSharp.fixed} />
                </div>
                <div className="text">
                <p>Hi! I'm James, a web developer based in Vancouver, BC.</p>

                <p>I've worked with clients including The Directors Guild of Canada, Frank Giustra, The Fiore Group, BC Parks Foundation, Catalytico, Think Tank Training Centre, Grosvenor Berkeley, Giustra Foundation, DeepGreen Metals, and more.</p>
                <p>My work has been featured in Variety magazine, on CBC.ca, and in various other publications.</p>
                <p>To speak with me about working on a project together, please contact me using <Link to='contact' smooth={true} offset={-50}>the form below</Link>.</p>
                </div>
            </div>
        </section>
    )
}