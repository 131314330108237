import React, { useEffect } from 'react';
import Chart from 'chart.js';
import Skills from '../../content/skills.yaml'


        const isPlural = function() {
            return true;
        }

Chart.defaults.global.tooltips.enabled = false;

Chart.pluginService.register({
    afterDraw: function(chartInstance) {
        var ctx = chartInstance.chart.ctx;

        // render the value of the chart above the bar
        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'center';
        ctx.fillStyle = '#fff';

        if (chartInstance.chart.width > 900) {
            const dataset = chartInstance.data.datasets[0];
            ctx.font = '12px IBM Plex Sans';

            for (let i = 0; i < dataset.data.length; i++) {
                const barElement = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._view;               
                const width = barElement.x - barElement.base;
                const midpoint = barElement.x - (width / 2);

                const range = dataset.data[i].join(' - ');
                const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;   
  
                ctx.fillText(range, midpoint,  model.y + 5);
            }
        } else {
            const dataset = chartInstance.data.datasets[0];
                ctx.font = 'bold 10px IBM Plex Sans';

            for (let i = 0; i < dataset.data.length; i++) {
                const barElement = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._view;               
                const width = barElement.x - barElement.base;
                const midpoint = barElement.x - (width / 2);

                const text = chartInstance.data.labels[i];
                const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;    
                ctx.fillText(text, midpoint, model.y + 4);
            }
        }
  }
});




export default function() {

    useEffect(() => {
        const ctx = document.getElementById('skill-chart').getContext('2d');
        const windowWidth = window.innerWidth;

        const barThickness = windowWidth > 900 ? 20 : 14;
        const showLabels = windowWidth > 900 ? true : false;
        const options = {
            type: 'horizontalBar',
            color: '#2E3440',
            data: {
                labels: null,
                datasets: [{
                    barThickness: barThickness,
                    label: 'data',
                    data: null,
                    backgroundColor: '#335072',
                }]
            },
            options: {
                // responsive: true,
                afterDraw:(skillChart, size) => {
                    console.log(skillChart)
                },
                onResize: (skillChart, size) => {                    
                    const showLabels = (size.width <= 900) ? false : true;
                    const barThickness = (size.width <= 900) ? 14 : 20;

                    skillChart.options.scales.yAxes[0].display = showLabels;
                    skillChart.data.datasets[0].barThickness = barThickness;
                },
                legend: {
                    position: 'top',
                    display: false,

                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontColor: "#335072",
                            display: showLabels,
                            fontSize: 18,                            
                            stepSize: 1,
                            type: 'string',
                            beginAtZero: true
                        }
                    }],
                    xAxes: [
                    {
                        position: 'top',
                        ticks: {
                            fontColor: "#335072",
                            fontSize: 14,
                            stepSize: 1,
                            beginAtZero: false,
                        }
                    },

                    ]
                },
                title: {
                    display: false,
                    text: 'Horizontal Floating Bars'
                }
            }
        }

        options.data.labels = Skills.content.map(i => i.skill.name);

        options.data.datasets[0].data = Skills.content.map((i) => {
            if (!i.skill.end) i.skill.end = new Date().getFullYear();
            return [i.skill.start, i.skill.end]
        });

        const skillChart = new Chart(ctx, options);



    })
    return <section className="skills">
            <h2>Technologies</h2>
                <ul className="skills-list">
                    {Skills.content.map((data, index)=> {
                    if (!data.skill.end) data.skill.end = new Date().getFullYear();
                    let plural = (data.skill.end - data.skill.start > 1) ? 's' : '';
                    return <li key={index}>
                        {data.skill.name} <span className="italic">({data.skill.end - data.skill.start} year{plural}
                        )</span>
                    </li>
                 })} 
                   
                </ul>
                <canvas className="skill-chart" id="skill-chart" ></canvas>
              
            </section>
}