import React, { useState } from 'react';
import PubSub from 'pubsub-js'
import { Link, Element } from 'react-scroll'

import CaseStudies from "../../content/case-studies.yaml"

export default function Integrations() {

    const [active, setActive] = useState(null);

    
    const subscriber = function (msg, name) {
        const index = CaseStudies.content.findIndex(c => c.study.name === name);
        setActive(index);
        const ele = document.getElementById(name);
        console.log(ele)
    };

    PubSub.subscribe('TOPIC', subscriber);

    return <section className="integrations">              
                <h2>Case Studies</h2>    
                    <ul className="case-studies unstyled">
                        {CaseStudies.content.map((data, index)=> {
                            return <li key={index}   
                                id={data.study.name}
                                >
                                 <Element name={data.study.name}>
                                    <h3 onClick={() => active === index ? setActive(null) : setActive(index)}>
                                        <span>{data.study.company}</span>
                      
                                    </h3>
                                        <div className={active === index ? 'content open' : 'content'}>
                                            <h4>Introduction</h4>
                                            <p>{data.study.problem}</p>
                                            <h4>Solution</h4>
                                            <ul className="solution">
                                                {data.study.solution.map((step, i)=>{
                                                return <li key={`step_${i}`}>{step.step}</li>
                                                })}
                                            </ul>
                                            <h4>Results</h4>
                                            <p>{data.study.result}</p> 
                                        </div>
                                    </Element>
                                 </li>
                        })} 
                    </ul>
            </section>

}